import React, { useState, useEffect, useReducer } from "react";
import { graphql } from "gatsby";
import Slide from "react-reveal/Slide";
import clsx from "clsx";
import { GatsbySeo } from "gatsby-plugin-next-seo";
import { FiShare2 } from "react-icons/fi";

import Layout from "../components/Layout";
import { useLocalStorage } from "../hooks/useStorage";
import { getProductPrice, getBatchsWithDate } from "../utils";

import Button from "../components/Button";
import { HTMLContent } from "../components/Content";
import Faq from "../components/Faq";
import PreviewCompatibleImage from "../components/PreviewCompatibleImage";
import Breadcrumbs from "../components/Breadcrumbs";
import useSiteMetadata from "../components/SiteMetadata";

const TrainingPage = ({ data }) => {
  const { markdownRemark: node } = data;
  const product = {
    ...node.frontmatter,
    slug: node.fields.slug,
    overview: node.fields.frontmattermd.overview.html,
    prerequisite: node.fields.frontmattermd.prerequisite.html,
    benefits: node.fields.frontmattermd.benefits.html,
  };
  const batchTypes = Array.from(new Set(product.batches.map((batch) => batch.type)));
  const batchesWithDate = getBatchsWithDate(product);
  const classroomBatches = batchesWithDate.filter((batch) => batch.type === "classroom");
  const [currentImage, setImage] = useState(product.images[0]);
  const [isSharingSupported, setIsSharingSupported] = useState(false);
  const [filesArray, setFilesArray] = useState([]);
  const { siteUrl } = useSiteMetadata();
  const [didScrolled, setDidScrolled] = useState(false);
  const [selectedBatchInLocalStorage, setSelectedBatchInStorage] = useLocalStorage(
    product.slug,
    JSON.stringify(classroomBatches.find((batch) => batch.isAvailable))
  );

  const [state, dispatch] = useReducer(
    (state, action) => {
      switch (action.type) {
        case "SET_BATCH_TYPE": {
          const typeBatches = batchesWithDate.filter((batch) => batch.type === action.payload);
          const availableBatch = typeBatches.find((batch) => batch.isAvailable);
          setSelectedBatchInStorage(JSON.stringify(availableBatch));
          return {
            ...state,
            activeBatchType: action.payload,
            selectedBatchDate: availableBatch ? availableBatch.date : undefined,
            selectedBatch: availableBatch,
          };
        }
        case "SET_DATE": {
          const typeBatches = batchesWithDate.filter(
            (batch) => batch.type === state.activeBatchType
          );
          const availableBatch = typeBatches.find((batch) => batch.date === action.payload);
          setSelectedBatchInStorage(JSON.stringify(availableBatch));
          return {
            ...state,
            selectedBatchDate: availableBatch ? availableBatch.date : undefined,
            selectedBatch: availableBatch,
          };
        }
        default:
          return state;
      }
    },
    {
      activeBatchType: "classroom",
      selectedBatchDate: classroomBatches.find((batch) => batch.isAvailable).date,
      selectedBatch: JSON.parse(selectedBatchInLocalStorage),
    }
  );

  useEffect(() => {
    window.addEventListener("scroll", () => {
      if (document.body.scrollTop > 800 || document.documentElement.scrollTop > 800) {
        setDidScrolled(true);
      } else {
        setDidScrolled(false);
      }
    });
  }, []);

  useEffect(() => {
    setIsSharingSupported(Boolean(navigator.share));
    if (navigator.canShare) {
      const image = product.images[0];
      fetch(image.publicURL)
        .then((r) => r.blob())
        .then((photoBlob) => {
          setFilesArray([
            new File([photoBlob], image.relativePath, {
              type: image.internal.mediaType,
            }),
          ]);
        });
    }
  }, []);

  async function shareProduct(e) {
    const shareObj = {
      title: product.seo.title,
      text: product.seo.description,
      url: `${siteUrl}${product.slug}`,
    };
    try {
      if (navigator.canShare && navigator.canShare({ files: filesArray })) {
        shareObj.files = filesArray;
      }
      await navigator.share(shareObj);
    } catch (e) {
      console.log(e);
    }
  }

  const currentBatches = batchesWithDate.filter((batch) => batch.type === state.activeBatchType);
  return (
    <Layout whiteHeader>
      <GatsbySeo
        title={product.seo.title}
        description={product.seo.description}
        openGraph={{
          url: `${siteUrl}${product.slug}`,
          title: product.seo.title,
          description: product.seo.description,
          images: [{ url: `${siteUrl}${product.seo.image.publicURL}` }],
          site_name: "MycoForest",
        }}
      />
      <section className="section">
        <div className="header">
          <Breadcrumbs
            inline
            items={[
              { title: "MycoForest", href: "/", active: true },
              { title: "Training", href: "/training", active: true },
              {
                title: product.name,
                href: product.slug,
                shortTitle: "Product",
                active: false,
              },
            ]}
          />
          {isSharingSupported && (
            <div className="share-container">
              <button onClick={shareProduct}>
                <span>
                  <FiShare2 size={20} />
                </span>
              </button>
            </div>
          )}
        </div>
        <div className="container">
          <div className="product">
            <div className="images-container">
              <div className="content">
                <div className="images">
                  <ul>
                    {product.images.map((image, index) => (
                      <li
                        key={index}
                        className={clsx(image === currentImage && "active")}
                        onMouseEnter={() => setImage(image)}
                        onClick={() => setImage(image)}
                      >
                        <PreviewCompatibleImage imageInfo={image} alt="product" />
                      </li>
                    ))}
                  </ul>
                  <div className="image">
                    <PreviewCompatibleImage imageInfo={currentImage} isZoomable alt="product" />
                    <h4>Batches</h4>
                    <div className="batches">
                      {batchTypes.map((batch) => (
                        <button
                          onClick={() => dispatch({ payload: batch, type: "SET_BATCH_TYPE" })}
                          className={batch === state.activeBatchType && "active"}
                          key={batch}
                        >
                          {batch}
                        </button>
                      ))}
                    </div>
                    <h4>Select Date</h4>
                    <div className="select-date">
                      <select
                        className="form-select"
                        value={state.selectedBatchDate}
                        onChange={(e) => dispatch({ payload: e.target.value, type: "SET_DATE" })}
                      >
                        {currentBatches.map((batch) => (
                          <option key={batch.date} value={batch.data} disabled={!batch.isAvailable}>
                            {batch.date} {batch.isAvailable ? "Available" : "Sold Out"}
                          </option>
                        ))}
                      </select>
                    </div>
                    <div className="btns">
                      <Button isInternal width={180} href={`${product.slug}/training-buy`}>
                        Buy Now
                      </Button>
                    </div>
                    <div className="price">
                      <p className="price-header">Special price</p>
                      <div className="price-info">
                        <div className="price">
                          ₹{" "}
                          {getProductPrice(state.selectedBatch.price, state.selectedBatch.discount)}
                        </div>
                        <div className="original-price">₹{state.selectedBatch.price}</div>
                        <div className="discount">{state.selectedBatch.discount}% off</div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="info">
              <h1>{product.name}</h1>
              <div className="ratings-reviews">
                <span className="ratings">
                  <span>{product.rating}</span>
                  <img
                    src="data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHdpZHRoPSIxMyIgaGVpZ2h0PSIxMiI+PHBhdGggZmlsbD0iI0ZGRiIgZD0iTTYuNSA5LjQzOWwtMy42NzQgMi4yMy45NC00LjI2LTMuMjEtMi44ODMgNC4yNTQtLjQwNEw2LjUuMTEybDEuNjkgNC4wMSA0LjI1NC40MDQtMy4yMSAyLjg4Mi45NCA0LjI2eiIvPjwvc3ZnPg=="
                    alt="star icon"
                  />
                </span>
                <span className="reviews">{product.reviews} Reviews</span>
              </div>
              <h4>Batches</h4>
              <div className="batches">
                {batchTypes.map((batch) => (
                  <button
                    onClick={() => dispatch({ payload: batch, type: "SET_BATCH_TYPE" })}
                    className={batch === state.activeBatchType && "active"}
                    key={batch}
                  >
                    {batch}
                  </button>
                ))}
              </div>
              <h4>Select Date</h4>
              <div className="select-date">
                <select
                  className="form-select"
                  value={state.selectedBatchDate}
                  onChange={(e) => dispatch({ payload: e.target.value, type: "SET_DATE" })}
                >
                  {currentBatches.map((batch) => (
                    <option key={batch.date} value={batch.data} disabled={!batch.isAvailable}>
                      {batch.date} {batch.isAvailable ? "Available" : "Sold Out"}
                    </option>
                  ))}
                </select>
              </div>
              <div className="info-cta-container">
                <div className="info-price-container">
                  <p className="price-header">Special price</p>
                  <div className="price-info">
                    <div className="price">
                      ₹ {getProductPrice(state.selectedBatch.price, state.selectedBatch.discount)}
                    </div>
                    <div className="original-price">₹{state.selectedBatch.price}</div>
                    <div className="discount">{state.selectedBatch.discount}% off</div>
                  </div>
                </div>
                <div className="cta">
                  <Button isInternal width={180} href={`${product.slug}/training-buy`}>
                    Buy Now
                  </Button>
                </div>
              </div>
              <h3>Overview</h3>
              <HTMLContent className="markdown" content={product.overview} />
              <h3>What You Will Learn</h3>
              <HTMLContent className="markdown" content={product.benefits} />
              <h3>Prerequisite</h3>
              <HTMLContent className="markdown" content={product.prerequisite} />
              <h3>Course Content</h3>
              <ul className="faqs">
                {product.courseContent.map((courseContent, index) => (
                  <Slide bottom key={index}>
                    <Faq
                      isHtml={true}
                      faq={{
                        question: courseContent.lesson_name,
                        answer: courseContent.lessonContent,
                      }}
                      key={index}
                    />
                  </Slide>
                ))}
              </ul>
              {product.faqs && (
                <>
                  <h3>Frequently Asked Questions</h3>
                  <ul className="faqs">
                    {product.faqs.map((faq, index) => (
                      <Slide bottom key={index}>
                        <Faq isHtml={false} faq={faq} key={index} />
                      </Slide>
                    ))}
                  </ul>
                </>
              )}
            </div>
          </div>
        </div>
        <style jsx>{`
          section {
            background: #ffff;
            padding: 32px;
            padding-bottom: 0;
          }

          .header {
            display: flex;
            justify-content: space-between;
            align-items: center;
            max-width: 1278px;
            margin: 0 auto;
            margin-bottom: 24px;
          }

          .share-container button {
            appearance: none;
            border: none;
            outline: none;
            background: transparent;
            color: #888;
            padding: 10px;
          }

          .container {
            max-width: 1278px;
            margin: 0 auto;
          }

          .product {
            display: flex;
            margin-bottom: 64px;
          }

          .price-info {
            flex: 1;
            font-family: "Lato", sans-serif;
            display: flex;
            align-items: center;
          }

          .original-price {
            color: #a0aec0;
            margin-right: 8px;
            font-size: 18px;
            text-decoration: line-through;
          }

          .discount {
            color: #ff8100;
            font-size: 14px;
            letter-spacing: -0.2px;
            font-weight: 600;
          }

          .price {
            color: #000;
            font-weight: 600;
            margin-right: 8px;
            font-size: 20px;
          }

          .images-container {
            flex: 1;
            order: 1;
          }

          .batches {
            display: flex;
            align-items: center;
            margin: 4px 0 12px 0;
          }

          .batches button {
            appearance: none;
            background: #fff;
            outline: none;
            border: 1px solid rgba(210, 214, 220, 1);
            border-radius: 8px;
            padding: 8px 16px;
            color: rgba(55, 65, 81, 1);
            min-width: 6rem;
            font-size: 18px;
            cursor: pointer;
            text-transform: capitalize;
            border-radius: 0;
          }

          .batches button:first-child {
            border-top-left-radius: 8px;
            border-bottom-left-radius: 8px;
          }

          .batches button:last-child {
            border-top-right-radius: 8px;
            border-bottom-right-radius: 8px;
          }

          .batches button:hover {
            color: rgba(14, 159, 110, 1);
            background: rgba(222, 247, 236, 1);
          }

          .batches button.active {
            background: rgba(90, 190, 127, 1);
            color: #fff;
          }

          .select-date {
            margin: 8px 0 12px 0;
          }

          .select-date .form-select {
            width: 100%;
            border-color: rgba(210, 214, 220, 1);
          }

          .images {
            min-height: 400px;
            display: flex;
          }
          .image h4 {
            margin-top: 4px;
          }

          .images ul {
            margin: 0;
            padding: 0;
            list-style: none;
            margin-right: 16px;
          }

          .images ul li {
            width: 95px;
            border: 1px solid #edf2f7;
            padding: 8px;
            display: flex;
            align-items: center;
            cursor: pointer;
          }

          .images ul li.active {
            border: 2px solid #2874f0;
          }

          .images ul li :global(.gatsby-image-wrapper) {
            width: 100%;
          }

          .images ul li :global(.gatsby-image-wrapper) :global(img) {
            border-radius: 0;
          }

          .images .image {
            width: 100%;
          }

          .info {
            flex: 1;
            order: 1;
            margin-left: 32px;
          }

          .product:nth-child(even) .images-container {
            order: 2;
          }

          .images-container :global(img) {
            border-radius: 8px;
          }

          .images-container .content {
            position: sticky;
            top: 100px;
          }

          .btns {
            margin-top: 16px;
            display: flex;
            justify-content: flex-end;
            margin-bottom: 8px;
          }

          .images-container .content .price {
            display: flex;
            justify-content: flex-end;
            align-items: baseline;
          }

          .images-container .content .price :global(.price-info) {
            flex: unset;
            margin-left: 16px;
          }

          h1,
          h3,
          h4 {
            font-family: "Open Sans", sans-serif;
          }

          h1 {
            font-weight: 700;
            font-size: 24px;
            margin-top: 0;
            margin-bottom: 8px;
          }

          h3 {
            color: #878787;
            font-weight: 600;
            margin-bottom: 0;
          }

          h4 {
            color: #878787;
            font-weight: 600;
            margin: 0;
          }

          .info-cta-container {
            display: flex;
            align-items: center;
          }

          .info-cta-container .cta {
            display: flex;
            align-items: center;
            flex: 1;
            margin-left: 16px;
          }

          .info-cta-container :global(.form-select) {
            width: 120px;
          }

          .info-cta-container :global(.btn-link) {
            width: 140px;
          }

          .price-header {
            margin: 0;
            padding: 0;
            margin-bottom: 4px;
            font-family: "Lato", sans-serif;
            color: #ff8100;
            font-size: 14px;
            letter-spacing: -0.2px;
            font-weight: 600;
          }

          .ratings-reviews {
            display: flex;
            font-family: "Lato", sans-serif;
          }

          .ratings {
            line-height: normal;
            display: inline-block;
            color: #fff;
            padding: 2px 4px 2px 6px;
            border-radius: 3px;
            font-weight: 500;
            font-size: 12px;
            vertical-align: middle;
            background-color: #ff8100;
            margin-bottom: 12px;
          }

          .ratings img {
            margin: 2px 0 0 2px;
            height: 10px;
          }

          .reviews {
            display: inline-block;
            margin-left: 8px;
          }

          .specifications,
          .faqs {
            margin: 0;
            padding: 0;
            margin-top: 16px;
            list-style: none;
            font-family: "Lato", sans-serif;
          }

          .specifications {
            border-radius: 8px;
            overflow: hidden;
          }

          .specifications li {
            border: 1px solid #d5d5d5;
          }

          .specifications li:not(:last-child) {
            border-bottom: none;
          }

          .specifications li span {
            padding: 8px 16px;
            display: inline-block;
          }

          .specifications li span.key {
            width: 120px;
            text-transform: capitalize;
            background-color: #dbf0e8;
            font-size: 16px;
            font-weight: 600;
          }

          .specifications li span.value {
            font-size: 14px;
          }

          @media (max-width: 1080px) {
            .product {
              flex-direction: column;
            }

            .info {
              max-width: 600px;
            }

            .images-container,
            .info {
              margin: 0 auto;
            }

            .images-container .image :global(.gatsby-image-wrapper) {
              height: 380px;
              width: 491px;
            }

            .images-container .image :global(.gatsby-image-wrapper) :global(img) {
              object-fit: contain !important;
            }

            .images-container .btns,
            .images-container .content .price {
              display: none;
            }
          }

          @media (max-width: 670px) {
            .images-container {
              width: 100%;
            }

            .images-container .images {
              flex-direction: column;
            }

            .images-container .images ul {
              order: 2;
              display: flex;
              margin: 0;
              margin-top: 8px;
              margin-bottom: 16px;
              justify-content: center;
            }

            .images-container .image {
              order: 1;
              border: 1px solid #eee;
              border-radius: 8px;
            }

            .images-container .image :global(.gatsby-image-wrapper) {
              height: 380px;
              width: 100%;
              max-width: 600px;
            }

            .info-cta-container {
              flex-direction: column;
              align-items: flex-start;
            }

            .info-cta-container .cta {
              display: flex;
              align-items: center;
              flex: 1;
              width: 100%;
              margin-left: 0;
              margin-right: 16px;
              margin-top: 16px;
            }

            .info-cta-container :global(.form-select) {
              width: auto;
            }

            .info-cta-container :global(.btn-link) {
              width: 140px;
            }
          }

          @media (max-width: 580px) {
            .specifications li span {
              display: block;
              width: 100%;
              text-align: left;
            }

            .specifications li span.key {
              width: 100%;
              font-size: 14px;
            }
          }

          @media (max-width: 480px) {
            section {
              padding: 32px 20px;
            }

            .info-cta-container .cta {
              flex-direction: column;
              align-items: flex-start;
              margin-right: 0;
            }

            .info-cta-container :global(.form-select) {
              width: 100%;
            }

            .info-cta-container :global(.btn-link) {
              margin-top: 16px;
              width: 100%;
            }
          }
        `}</style>
      </section>
    </Layout>
  );
};

export default TrainingPage;

export const trainingQuery = graphql`
  query TraningPage($id: String!) {
    markdownRemark(id: { eq: $id }) {
      frontmatter {
        seo {
          title
          description
          image {
            publicURL
          }
        }
        name
        isFeatured
        reviews
        rating
        batches {
          price
          discount
          duration
          start_date
          end_date
          isAvailable
          type
        }
        faqs {
          question
          answer
        }
        images {
          relativePath
          publicURL
          internal {
            mediaType
          }
          childImageSharp {
            original {
              src
            }
            fluid {
              ...GatsbyImageSharpFluid_withWebp
            }
          }
        }
        courseContent {
          lesson_name
          lessonContent
        }
      }
      fields {
        slug
        frontmattermd {
          overview {
            html
          }
          prerequisite {
            html
          }
          benefits {
            html
          }
        }
      }
    }
  }
`;
